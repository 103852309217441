import React, {useState,useEffect} from 'react';
import { Link } from "react-router-dom";

import {useNavigate} from "react-router-dom";


// Services
import ApiObject from '../../Services/Api';
import Cookie from '../../Services/Cookie';

// UI components
import {Row, Col, Form, Button, Alert, Spinner} from 'react-bootstrap';
import Logo from '../../Assets/Images/logo_grignoux.png';


export default function Login() {

    let navigate = useNavigate();

    const [isLoading,setIsLoading] = useState(false);
    const [hasError,setHasError] = useState(false);
    const [submitFields,setSubmitFields] = useState({email:"",password:""});



    useEffect(() => {
        const cookie = Cookie.get('GRUL');
        if(cookie) navigate('/dashboard');
    },[]);


    const attemptLogin = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if(!submitFields.email || submitFields.email.trim() === ''){
            setHasError(true);
        }else if(!submitFields.password || submitFields.password.trim() === ''){
            setHasError(true);
        }else{
            setIsLoading(true);
            ApiObject.call('user/login','POST',submitFields,false).then((response) => {
                if(response.token){
                    Cookie.set('GRUL',response.token,1);
                    setIsLoading(false);
                    window.location.reload();
                }else{
                    setHasError(true);
                }
            },(error) => {
                console.log(error);
            });
        }

    };

    return (
        <section className={"loginForm"}>
                <Row className={"loginRow"}>
                    <Col className={"formCol"} md={6} sm={12}>
                        <img src={Logo} className={"loginLogoApp"} alt={"Grignoux"} />
                        <Form onSubmit={attemptLogin} className={'formContainer'} >
                            <div className={'login_nav'}>
                                <Link to={'/login'} className={'link active'}>Connexion</Link>
                                <Link to={'/register'} className={'link'}>Inscription</Link>
                            </div>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Identifiant</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="Votre Adresse email"
                                    name={"email"}
                                    value={submitFields.email}
                                    onChange={(e) => {setSubmitFields({...submitFields,email : e.target.value})}}
                                />

                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Mot de passe</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="Votre mot de passe"
                                    value={submitFields.password}
                                    onChange={(e) => {setSubmitFields({...submitFields,password : e.target.value})}}
                                />
                            </Form.Group>
                            <Button
                                variant="primary"
                                type="submit"

                            >
                                {isLoading?
                                    <Spinner animation="border" size="sm" />
                                    :
                                    <span>Connexion</span>
                                }
                            </Button>
                            <Link to="/recover" className={"lostPassword"}>Mot de passe perdu ?</Link>
                            {hasError?
                                <Alert variant="danger" className={"errorMessage"}>
                                    <p>
                                        Veuillez vérifier vos informations avant de continuer.
                                    </p>
                                </Alert>
                                :
                                <div></div>
                            }
                        </Form>
                    </Col>
                    <Col md={6} sm={12} className={"loginBackgroundCol"} />
                </Row>
        </section>
    );
}

