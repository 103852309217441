import React, {useState} from 'react';
import { Link } from "react-router-dom";

// Services
import ApiObject from '../../Services/Api';
import Cookie from '../../Services/Cookie';

// UI components
import {Row, Col, Form, Button, Alert} from 'react-bootstrap';
import Logo from '../../Assets/Images/logo_grignoux.png';

import { useParams } from "react-router-dom";

export default function Validate() {

    const [hasError,setHasError] = useState(false);
    const [hasSubmitted,setHasSubmitted] = useState(false);

    let { token } = useParams();


    const validateAccount = () => {

        if(!hasSubmitted){

            let requestFields = {hash : token};

            ApiObject.call('user/validate','POST',requestFields,false).then((response) => {
                console.log("returned datas");
                console.log(response);

                if(response.id){
                    setHasError(false);
                }else{
                    setHasError(true);
                }
            },(error) => {
                console.log('ERROR');
                console.log(error);
                setHasError(true);
            });

            setHasSubmitted(true);


        }
    };

    if(token){
        validateAccount();
    }

    return (
        <section className={"loginForm"}>
                <Row className={"loginRow"}>
                    <Col className={"formCol"} md={6} sm={12}>
                        <img src={Logo} className={"loginLogoApp"} alt={"Grignoux"} />

                        <div className={"valideInfo"}>
                            {token && !hasError?
                                <div  className={"success"}>
                                    <span>Parfait, votre compte a bien été validé!</span>
                                    <br />
                                    <Link to={'/login'} className={'link active'}>Connexion</Link>
                                </div>
                                :
                                <div  className={"error"}>
                                    Impossible de valider votre compte.
                                </div>
                            }
                        </div>

                    </Col>
                    <Col md={6} sm={12} className={"loginBackgroundCol"} />
                </Row>
        </section>
    );
}

