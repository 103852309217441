import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

// Ui Components
import {
	Button,
	Col,
	Container,
	FloatingLabel,
	Form,
	Row,
	Spinner,
} from "react-bootstrap";
import "react-tabs/style/react-tabs.css";

import Header from "../Parts/Header";
import DefaultAvatar from "../../Assets/Images/default.png";
import ApiObject from "../../Services/Api";
import Api from "../../Services/Api";
import Cookie from "../../Services/Cookie";

export default function DashboardIndex() {
	const [user, setUser] = useState({
		name: "",
		firstname: "",
		email: "",
		phone: "",
		address: "",
		locality: "",
		cp: "",
	});
	const [isLoading, setIsLoading] = useState(false);
	const [isAvatarLoading, setIsAvatarLoading] = useState(false);
	const [avatar, setAvatar] = useState(DefaultAvatar);
	const [avatarBase64, setAvatarBase64] = useState("");

	const [newPassword, setNewPassword] = useState("");
	const [confirmNewPassword, setConfirmNewPassword] = useState("");
	const [errorPassword, setErrorPassword] = useState(false);
	const [validated, setValidated] = useState(false);

	let uploadFile = React.createRef();
	let navigate = useNavigate();

	// redux elements
	let dispatch = useDispatch();

	useEffect(() => {
		setIsLoading(true);
		Api.call("user/me", "GET", {}, true).then(
			(response) => {
				if (response.id) {
					if (response.imageUri !== "") {
						setAvatar(response.imageUri);
					}

					setUser(response);
					dispatch({
						type: "UPDATE_USER",
						payload: response,
					});
				} else {
					navigate("/login");
				}

				setIsLoading(false);
			},
			(error) => {}
		);
	}, []);

	const saveUserInfos = (e) => {
		e.preventDefault();
		e.stopPropagation();

		setIsLoading(true);

		let userCandidate = { ...user, password: "" };

		if (avatarBase64) {
			userCandidate = { ...userCandidate, avatar: avatarBase64 };
		}

		ApiObject.call("user/update", "POST", userCandidate, true).then(
			(response) => {
				setTimeout(() => setIsLoading(false), 500);
			},
			(error) => {
				console.log(error);
			}
		);
	};

	const triggerUpload = () => {
		setIsAvatarLoading(true);
		uploadFile.current.click();
	};

	const getUploadedFile = (file) => {
		//create preview
		setAvatar(URL.createObjectURL(file));

		// save base64 string
		var reader = new FileReader();
		reader.readAsDataURL(file);

		reader.onload = function () {
			setAvatarBase64(reader.result);
			setIsAvatarLoading(false);
		};
		reader.onerror = function (error) {
			console.log("Error: ", error);
		};
	};

	const updateUser = (userCandidate) => {
		setUser(userCandidate);

		dispatch({
			type: "UPDATE_USER",
			payload: userCandidate,
		});
	};

	const checkPassword = (e) => {
		if (newPassword === confirmNewPassword) {
			setErrorPassword(false);
			const newNuser = { ...user, password: newPassword };
			updateUser(newNuser);
			setIsLoading(true);
			ApiObject.call("user/update", "POST", newNuser, true).then(
				(response) => {
					setTimeout(() => setValidated(true), 500);
					setTimeout(() => setIsLoading(false), 500);
				},
				(error) => {
					console.log(error);
				}
			);
		} else {
			setErrorPassword(true);
		}
	};

	const logout = () => {
		Cookie.remove("GRUL");
		navigate("/login");
	};

	return (
		<section className={"dashboard"}>
			<Header />
			<Container>
				<div className={"dashboard_generals"}>
					<Form onSubmit={saveUserInfos}>
						<Row className={"row_title"}>
							<Col md={10}>
								<h3>Mes informations</h3>
							</Col>
							<Col className={"col_action"} md={2}>
								<Button variant="primary" type="submit">
									{isLoading ? (
										<Spinner animation="border" size="sm" />
									) : (
										<span> Enregistrer</span>
									)}
								</Button>
							</Col>
						</Row>
						<Row>
							<Col md={3}>
								<Row className={"row_avatar"}>
									<span>Photo d'identité</span>

									<img src={avatar} className={"avatar"} alt={"Avatar"} />
									<input
										className={"upload_avatar_input"}
										type={"file"}
										ref={uploadFile}
										onChange={(input) => getUploadedFile(input.target.files[0])}
									/>
									<Button
										className={"upload_avatar_button"}
										variant="outline-secondary"
										size="sm"
										onClick={triggerUpload}
									>
										{isAvatarLoading ? (
											<Spinner animation="border" size="sm" />
										) : (
											<span>modifier</span>
										)}
									</Button>
								</Row>
							</Col>
							<Col md={9}>
								<Row>
									<Col>
										<Form.Group className="mb-3">
											<FloatingLabel label="Nom" className="mb-3">
												<Form.Control
													type="text"
													placeholder="Votre nom"
													name={"email"}
													value={user.name}
													onChange={(e) => {
														updateUser({ ...user, name: e.target.value });
													}}
												/>
											</FloatingLabel>
										</Form.Group>
									</Col>
									<Col>
										<Form.Group className="mb-3">
											<FloatingLabel label="Prénom" className="mb-3">
												<Form.Control
													type="text"
													placeholder="Votre prénom"
													name={"email"}
													value={user.firstname}
													onChange={(e) => {
														updateUser({
															...user,
															firstname: e.target.value,
														});
													}}
												/>
											</FloatingLabel>
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col md={9}>
										<Form.Group className="mb-3">
											<FloatingLabel label="Email" className="mb-3">
												<Form.Control
													type="email"
													placeholder="Votre adresse email"
													name={"email"}
													value={user.email}
													onChange={(e) => {
														updateUser({ ...user, email: e.target.value });
													}}
												/>
											</FloatingLabel>
										</Form.Group>
									</Col>
									<Col md={3}>
										<Form.Group className="mb-3">
											<FloatingLabel label="Sexe" className="mb-3">
												<Form.Select
													aria-label="Sexe"
													value={user?.sexe}
													onChange={(e) => {
														updateUser({ ...user, sexe: e.target.value });
													}}
												>
													<option>Sélectionner votre sexe</option>
													<option value="homme">Homme</option>
													<option value="femme">Femme</option>
													<option value="autre">Autre</option>
													<option value="-">Préfère ne pas le dire</option>
												</Form.Select>
											</FloatingLabel>
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col>
										<Form.Group className="mb-3">
											<FloatingLabel
												label="Votre date de naissance"
												className="mb-3"
											>
												<Form.Control
													type="date"
													placeholder="Votre date de naissance"
													name={"date"}
													value={
														user.birthdate
															? new Date(user.birthdate).getFullYear() +
															  "-" +
															  (
																	"0" +
																	(new Date(user.birthdate).getMonth() + 1)
															  ).slice(-2) +
															  "-" +
															  (
																	"0" + new Date(user.birthdate).getDate()
															  ).slice(-2)
															: ""
													}
													onChange={(e) => {
														console.log(e.target.value);
														updateUser({
															...user,
															birthdate: e.target.value,
														});
													}}
												/>
											</FloatingLabel>
										</Form.Group>
									</Col>
									<Col>
										<Form.Group className="mb-3">
											<FloatingLabel label="Téléphone" className="mb-3">
												<Form.Control
													type="text"
													placeholder="Votre numéro de téléphone"
													name={"email"}
													value={user.phone}
													onChange={(e) => {
														updateUser({ ...user, phone: e.target.value });
													}}
												/>
											</FloatingLabel>
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col md={6}>
										<Form.Group className="mb-3">
											<FloatingLabel
												label="Adresse (Rue et numéro)"
												className="mb-3"
											>
												<Form.Control
													type="text"
													placeholder="Votre adresse"
													name={"address"}
													value={user.address}
													onChange={(e) => {
														updateUser({
															...user,
															address: e.target.value,
														});
													}}
												/>
											</FloatingLabel>
										</Form.Group>
									</Col>
									<Col md={3}>
										<Form.Group className="mb-3">
											<FloatingLabel label="Code postal" className="mb-3">
												<Form.Control
													type="text"
													placeholder="Votre code postal"
													name={"cp"}
													value={user.cp}
													onChange={(e) => {
														updateUser({ ...user, cp: e.target.value });
													}}
												/>
											</FloatingLabel>
										</Form.Group>
									</Col>
									<Col md={3}>
										<Form.Group className="mb-3">
											<FloatingLabel label="Ville" className="mb-3">
												<Form.Control
													type="text"
													placeholder="Votre ville"
													name={"city"}
													value={user.locality}
													onChange={(e) => {
														updateUser({
															...user,
															locality: e.target.value,
														});
													}}
												/>
											</FloatingLabel>
										</Form.Group>
									</Col>
								</Row>

								<Row>
									<Col md={6} className="extraUserInfos">
										<span className="title">Informations complémentaires</span>
										<Row>
											<Form.Group>
												<FloatingLabel
													label="Ētes-vous enseignant.e ?"
													className="mb-3"
												>
													<Form.Select
														aria-label="Ētes-vous nseignant.e ?"
														value={user?.is_prof}
														onChange={(e) => {
															updateUser({
																...user,
																is_prof: e.target.value,
															});
														}}
													>
														<option>Sélectionner une option</option>
														<option value="1">Oui</option>
														<option value="0">Non</option>
													</Form.Select>
												</FloatingLabel>
											</Form.Group>
										</Row>
										<Row>
											<Form.Group>
												<FloatingLabel
													label="Taille de votre famille"
													className="mb-3"
												>
													<Form.Select
														aria-label="Taille de votre famille"
														value={user?.family}
														onChange={(e) => {
															updateUser({
																...user,
																family: e.target.value,
															});
														}}
													>
														<option>Sélectionner une option</option>
														<option value="1">1</option>
														<option value="2">2</option>
														<option value="3">3</option>
														<option value="4">4</option>
														<option value="5+">5+</option>
													</Form.Select>
												</FloatingLabel>
											</Form.Group>
										</Row>
									</Col>
									<Col md={6} className="extraUserInfos password">
										<span className="title">Modification du mot de passe</span>

										<Row>
											<Form.Group className="mb-3">
												<FloatingLabel
													label="Nouveau mot de passe"
													className=""
												>
													<Form.Control
														type="password"
														placeholder="Nouveau mot de passe"
														name={"password"}
														value={newPassword}
														className={errorPassword ? "error" : ""}
														onChange={(e) => {
															setNewPassword(e.target.value);
														}}
													/>
												</FloatingLabel>
											</Form.Group>
										</Row>
										<Row>
											<Form.Group className="mb-3">
												<FloatingLabel
													label="Confirmation du nouveau mot de passe"
													className=""
												>
													<Form.Control
														type="password"
														placeholder="Confirmation du nouveau mot de passe"
														name={"confirmPassword"}
														value={confirmNewPassword}
														className={errorPassword ? "error" : ""}
														onChange={(e) => {
															setConfirmNewPassword(e.target.value);
														}}
													/>
												</FloatingLabel>
											</Form.Group>
										</Row>
										<Row>
											<Button onClick={checkPassword} variant="primary">
												{isLoading ? (
													<Spinner animation="border" size="sm" />
												) : (
													<span>Modifer mon mot de passe</span>
												)}
											</Button>
											{errorPassword && (
												<span className="error_message">
													Les mots de passe ne correspondent pas
												</span>
											)}
										</Row>
									</Col>
								</Row>
							</Col>
						</Row>
					</Form>
				</div>
			</Container>
			{validated && (
				<div className="validated">
					<div className="inner">
						<span>Votre mot de passe à été changé.</span>
						<strong>
							Veuillez vous reconnecter avec votre nouveau mot de passe.
						</strong>
						<Button onClick={logout}>Reconnexion</Button>
					</div>
				</div>
			)}
		</section>
	);
}
