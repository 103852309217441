import React from 'react';
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import './Assets/Styles/main.css';


// Views
import Login from './Components/Login/Login';
import Register from './Components/Login/Register';
import Recover from './Components/Login/Recover';
import Validate from './Components/Login/Validate';

// pages
import StudentForm from './Components/Pages/StudentForm';
import StudentValidate from './Components/Pages/StudentValidate';


import Dashboard from './Components/Dashboard/Index';
import Orders from './Components/Dashboard/Orders';
import Settings from './Components/Dashboard/Settings';
import Lost from './Components/404';

function App() {

  return (
      <BrowserRouter>
          <div className="App">
              <Routes>
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/orders" element={<Orders />} />
                  <Route path="/settings" element={<Settings />} />
                  <Route path="login" element={<Login />} />
                  <Route path="register" element={<Register />} />
                  <Route path="recover" element={<Recover />} />
                  <Route path="validate/:token" element={<Validate />} />
                  <Route path="recover/:token" element={<Recover />} />
                  <Route path="passeport" element={<StudentForm />} />
                  <Route path="passeport-validation/:token" element={<StudentValidate />} />
                  <Route path="*" element={<Lost />} />
              </Routes>
          </div>
      </BrowserRouter>
  );
}

export default App;
