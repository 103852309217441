import Config from '../Config';
import Cookie from "./Cookie";


const token  =  Cookie.get('GRUL');
const apiUrl = Config.apiUrl;

const securedRequest = (endpoint, httpMethod, requestDatas) => {
    let url = apiUrl + endpoint;
    return new Promise((resolve, reject) => {

        if(token){
            let options = {
                method: httpMethod,
                headers: {
                    'Accept': 'application/json',
                    "Authorization": 'Basic ' + token,
                    "Content-Type" : "application/json"
                }
            };
            if(Object.keys(requestDatas).length > 0){
                options.body = JSON.stringify(requestDatas);
            }
            fetch(url, options)
                .then(response => {
                    if (
                        response.status !== 200 &&
                        response.status !== 201 &&
                        response.status !== 204
                    ) {
                        reject(response)
                    } else {
                        response.text().then(
                            text => {
                                try {
                                    resolve(JSON.parse(text));
                                } catch (error) {
                                    resolve(text);
                                }
                            },
                            error => {
                                console.warn(error);
                                reject(error);
                            },
                        );
                    }
                }, reject)
                .catch(reject);
        }else{
            resolve('no token');
        }



    });
};

const simpleRequest = (
    endpoint,
    httpMethod,
    requestDatas,
    external = false,
) => {
    return new Promise((resolve, reject) => {
        let url = apiUrl + endpoint;
        let options = {
            method: httpMethod,
        };

        if(Object.keys(requestDatas).length > 0){
            options.headers =  {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            };

            options.body =  JSON.stringify(requestDatas)
        }

        if (external) {
            url = endpoint;
        }

        fetch(url, options)
            .then(function (response) {

                if (
                    response.status !== 200 &&
                    response.status !== 201 &&
                    response.status !== 204
                ) {
                    response.text().then(
                        text => {
                            console.log(text);
                            try {
                                resolve(JSON.parse(text));
                            } catch (error) {
                                resolve(text);
                            }
                        },
                        error => {
                            console.warn(error);
                            reject(error);
                        },
                    );
                } else {
                    response.text().then(
                        text => {
                            try {
                                resolve(JSON.parse(text));
                            } catch (error) {
                                console.log(text);
                                resolve(text);
                            }
                        },
                        error => {
                            reject(error);
                        },
                    );
                }
            }, reject)
            .catch(reject);
    });
};

let ApiObject = {
    call(endPoint, method, datas, secured = true) {
        if (secured) {
            return securedRequest(endPoint, method, datas);
        } else {
            return simpleRequest(endPoint, method, datas);
        }
    }
}
export default ApiObject;
