import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";

// Services
import ApiObject from '../../Services/Api';
import Cookie from '../../Services/Cookie';

// UI components
import {Row, Col, Form, Button, Alert} from 'react-bootstrap';
import Logo from '../../Assets/Images/logo_grignoux.png';

import { useParams } from "react-router-dom";

export default function Login() {

    const [hasError,setHasError] = useState(false);
    const [hasSubmitted,setHasSubmitted] = useState(false);
    const [submitFields,setSubmitFields] = useState({password:"",validation_password:""});

    let { token } = useParams();


    let navigate = useNavigate();

    useEffect(() => {
        const cookie = Cookie.get('GRUL');
        if(cookie) navigate('/dashboard');
    },[]);


    const submitRecoverRequest = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if(!submitFields.login || submitFields.login.trim() === ''){
            setHasError(true);
        }else{
            ApiObject.call('user/recover','POST',submitFields,false).then((response) => {
                setHasSubmitted(true);
            },(error) => {
                console.log(error);
            });
        }
    };

    const resetPassword = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if(
            !submitFields.password ||
            !submitFields.validation_password ||
            submitFields.password.trim() === '' ||
            submitFields.validation_password.trim() === '' ||
            submitFields.password !== submitFields.validation_password
        ){
            setHasError(true);
        }else{

            let requestFields = {...submitFields,hash : token};

            ApiObject.call('user/resetpassword','POST',requestFields,false).then((response) => {
                    setHasSubmitted(true);
                    setHasError(false);
                },(error) => {
                    console.log(error);
                });
        }

    };

    return (
        <section className={"loginForm"}>
                <Row className={"loginRow"}>
                    <Col className={"formCol"} md={6} sm={12}>
                        <img src={Logo} className={"loginLogoApp"} alt={"Grignoux"} />

                        {token?
                            <div>
                                {!hasSubmitted?
                                    <Form onSubmit={resetPassword} className={'formContainer'} >
                                        <strong className={'title'}>Réinitialiser mon mot de passe</strong>
                                        <Form.Group className="mb-3" controlId="formBasicPassword">
                                            <Form.Label>Nouveau mot de passe</Form.Label>
                                            <Form.Control
                                                type="password"
                                                placeholder="Votre mot de passe"
                                                value={submitFields.password}
                                                onChange={(e) => {setSubmitFields({...submitFields,password : e.target.value})}}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="formBasicPassword">
                                            <Form.Label>Validation du mot de passe</Form.Label>
                                            <Form.Control
                                                type="password"
                                                placeholder="Votre mot de passe"
                                                value={submitFields.validation_password}
                                                onChange={(e) => {setSubmitFields({...submitFields,validation_password : e.target.value})}}
                                            />
                                        </Form.Group>

                                        <Button
                                            variant="primary"
                                            type="submit"

                                        >
                                            Modifier mon mot de passe
                                        </Button>

                                    </Form>
                                    :
                                    <div className={"successMessage"}>
                                        <span>Votre nouveau mot de passe à été enregistré.</span>
                                        <span>Vous pouvez dès à présent vous connecter avec vos identifiants:</span>
                                        <Link className={"linkToLogin"} to="/login">Me connecter à mon compte</Link>
                                    </div>

                                }
                            </div>
                            :
                            <div>
                                {!hasSubmitted?
                                    <Form onSubmit={submitRecoverRequest} className={'formContainer'} >
                                    <strong className={'title'}>Récupérer mon mot de passe</strong>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Adresse email</Form.Label>
                                    <Form.Control
                                    type="email"
                                    placeholder="Votre Adresse email"
                                    name={"email"}
                                    value={submitFields.login}
                                    onChange={(e) => {setSubmitFields({...submitFields,login : e.target.value})}}
                                    />

                                    </Form.Group>

                                    <Button
                                    variant="primary"
                                    type="submit"

                                    >
                                    Récupérer mon mot de passe
                                    </Button>

                                    </Form>
                                :
                                    <div className={"successMessage"}>
                                        <span>Votre demande de récupération de mot de passe à été effectuée.</span>
                                        <br />
                                        <br />
                                        <span>Un email de confirmation a été envoyé à l'adresse renseignée.</span>
                                    </div>
                                }
                            </div>
                        }

                        {hasError?
                            <Alert className={'alertError'} variant="danger">
                                <p>
                                    Veuillez vérifier vos informations avant de continuer.
                                </p>
                            </Alert>
                            :
                            <div></div>
                        }
                    </Col>
                    <Col md={6} sm={12} className={"loginBackgroundCol"} />
                </Row>
        </section>
    );
}

