import React,{useState} from "react";
import { Link } from "react-router-dom";
import {useNavigate} from "react-router-dom";

// Services
import Cookie from '../../Services/Cookie';

// UI components
import {Container,Row,Col,Button} from 'react-bootstrap';
import Logo from '../../Assets/Images/logo_grignoux.png';

export default function Header() {
    let navigate = useNavigate();

    const [navClass,setNavClass] = useState('menu');
    const [toggleClass,setToggleClass] = useState('toggle_menu');

    const logout = () => {
        Cookie.remove('GRUL');
        navigate('/login');
    };

    const toggleMenu = () => {
        if(navClass == 'menu'){
            setNavClass('menu open');
            setToggleClass('toggle_menu open');
        }else{
            setNavClass('menu');
            setToggleClass('toggle_menu');
        }
    }

    return (
        <header className={"header"}>
            <Container>
                <Row className={"headerRow"}>
                    <Col>
                        <img className={'header_logo'} src={Logo} alt={"Grignoux"} />
                    </Col>
                    <Col className={navClass}>
                        <Link to={'/'}>Informations générales</Link>
                        <Link to={'/settings'}>Mes préférences</Link>
                        {/*<Link to={'/orders'}>Mes cartes</Link>*/}
                        <Button onClick={logout}>Déconnexion</Button>
                    </Col>
                    <Col className={toggleClass} onClick={toggleMenu}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </Col>
                </Row>
            </Container>
        </header>
    );
}
