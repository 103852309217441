//import Config from "../../Config";
import Cookie from "../../Services/Cookie";
const token = Cookie.get("GRUL");

const Config = {
	apiUrl: "http://apigrignoux.brainmade.io/",
};

class abstractApiObject {
	controller;
	apiUrl;

	constructor() {
		this.apiUrl = Config.apiUrl;
	}

	call(params) {
		return new Promise((resolve, reject) => {
			const init = {
				method: params.method,
				headers: {},
			};

			if (params.method === "POST") {
				init.headers = {
					Accept: "application/json",
					"Content-Type": "application/json",
				};
				init.body = JSON.stringify(params.body);
			}

			if (params.secured) {
				init.headers.Authorization = "Basic " + token;
			}

			const url = this.apiUrl + params.url;

			try {
				fetch(url, init)
					.then(
						(response) => {
							try {
								response.json().then(
									(datas) => {
										console.log(
											"RESPONSE API : ",
											url,
											response.status,
											response.statusText,
											datas
										);
										if (response.status !== 200) {
											reject(datas);
										} else if (datas?.status === "error") {
											reject(datas);
										} else if (!datas) {
											reject(datas);
										} else {
											resolve(datas);
										}
									},
									() => {
										reject({
											type: "response_datas",
											message: "API.ERRORS.JSON_error_datas",
										});
									}
								);
							} catch (e) {
								reject({
									type: "response_parsing",
									message: "API.ERRORS.JSON_fail_parsing",
								});
							}
						},
						() => {
							reject({
								type: "fetch_error",
								message: "API.ERRORS.fetch_error",
							});
						}
					)
					.catch(() => {
						reject({
							type: "fetch_error",
							message: "API.ERRORS.fetch_error",
						});
					});
			} catch (e) {
				reject({
					type: "response_error",
					message: "API.ERRORS.catch_fetch_error",
				});
			}
		});
	}

	get() {
		const params = {
			url: this.controller,
			method: "GET",
			body: {},
			secured: true,
		};
		return new Promise((resolve, reject) => {
			this.call(params).then(
				(result) => {
					resolve(result);
				},
				(error) => {
					reject(error);
				}
			);
		});
	}

	getOne(id) {
		const params = {
			url: this.controller + "/" + id,
			method: "GET",
			body: {},
			secured: true,
		};
		return new Promise((resolve, reject) => {
			this.call(params).then(
				(result) => {
					resolve(result);
				},
				(error) => {
					reject(error);
				}
			);
		});
	}

	post(datas) {
		return new Promise((resolve, reject) => {
			const params = {
				url: this.controller,
				method: "POST",
				body: datas,
				secured: true,
			};

			this.call(params).then(
				(result) => {
					resolve(result);
				},
				(error) => {
					reject(error);
				}
			);
		});
	}

	put(datas) {
		const params = {
			url: this.controller + "/" + datas.id,
			method: "POST",
			body: datas,
			secured: true,
		};

		return new Promise((resolve, reject) => {
			this.call(params).then(
				(result) => {
					resolve(result);
				},
				(error) => {
					reject(error);
				}
			);
		});
	}

	delete(id) {
		return new Promise((resolve, reject) => {
			const params = {
				url: this.controller + "/" + id,
				method: "delete",
				body: {},
				secured: true,
			};

			this.call(params).then(
				(result) => {
					resolve(result);
				},
				(error) => {
					reject(error);
				}
			);
		});
	}
}
export default abstractApiObject;
