import abstractApiObject from "./abstractApiObject";

class userApiObject extends abstractApiObject {
  constructor() {
    super();
    this.controller = "user";
  }

  checkToken() {
    const params = {
      url: this.controller + "/checktoken",
      method: "GET",
      body: {},
      secured: true,
    };
    return new Promise((resolve, reject) => {
      this.call(params).then(
        (result: any) => {
          if (result?.status === "success") {
            resolve(result?.data);
          } else {
            reject(result);
          }
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  postLogin(email: string, password: string) {
    const params = {
      url: this.controller + "/login",
      method: "POST",
      body: { email: email, password: password, use_jwt: true },
      secured: false,
    };
    return new Promise((resolve, reject) => {
      this.call(params).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  postRegister(email: string, password: string, id_country: string) {
    const params = {
      url: this.controller + "/register",
      method: "POST",
      body: {
        email: email,
        password: password,
        id_country: id_country,
      },
      secured: false,
    };
    return new Promise((resolve, reject) => {
      this.call(params).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  postValidate(hash: string) {
    const params = {
      url: this.controller + "/validate",
      method: "POST",
      body: {
        hash: hash,
      },
      secured: false,
    };
    return new Promise((resolve, reject) => {
      this.call(params).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  postRecover(email: string) {
    const params = {
      url: this.controller + "/reset",
      method: "POST",
      body: {
        email: email,
      },
      secured: false,
    };
    return new Promise((resolve, reject) => {
      this.call(params).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  postResetpassword(hash: string, password: string) {
    const params = {
      url: this.controller + "/resetpassword",
      method: "POST",
      body: {
        hash: hash,
        password: password,
      },
      secured: false,
    };
    return new Promise((resolve, reject) => {
      this.call(params).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getStats() {
    const params = {
      url: this.controller + "/stats/",
      method: "GET",
      body: {},
      secured: true,
    };
    return new Promise((resolve, reject) => {
      this.call(params).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  postCredentials(provider: string, credentials: string) {
    const params = {
      url: "oauth/credentials",
      method: "POST",
      body: {
        provider: provider,
        credentials: credentials,
      },
      secured: false,
    };
    return new Promise((resolve, reject) => {
      this.call(params).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  postPassword(password: string) {
    const params = {
      url: "user/password",
      method: "POST",
      body: {
        password: password,
      },
      secured: true,
    };
    return new Promise((resolve, reject) => {
      this.call(params).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  postSettings(settings: any) {
    const params = {
      url: "settings/save",
      method: "POST",
      body: settings,
      secured: true,
    };
    return new Promise((resolve, reject) => {
      this.call(params).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getNewsletters(){
    const params = {
        url: "user/newsletters",
        method: "GET",
        body: {},
        secured: false,
      };
      return new Promise((resolve, reject) => {
        this.call(params).then(
          (result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          }
        );
      });
  }


  postNewsletters(newsletters){
    const params = {
        url: "user/newsletters",
        method: "POST",
        body: {newsletters},
        secured: true,
      };
      return new Promise((resolve, reject) => {
        this.call(params).then(
          (result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          }
        );
      });
  }

  deleteNewsletters(newsletters){
    const params = {
        url: "user/newsletters",
        method: "DELETE",
        body: {newsletters},
        secured: true,
      };
      return new Promise((resolve, reject) => {
        this.call(params).then(
          (result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          }
        );
      });
  }
}

export default userApiObject;
