

import newUserApiObject from "../Models/UserModel";

const userApi = new newUserApiObject();
const defaultReturnObject = {};

export const getUser = () => {
	const returnDatas = defaultReturnObject;

	return new Promise((resolve, reject) => {
		userApi.checkToken().then(
			(result) => {
				if (!result?.id) {
					window.location.href = "/login";
					returnDatas.message = "token_invalid";
					reject(returnDatas);
				} else {
					returnDatas.status = "success";
					returnDatas.data = result;
					returnDatas.message = "";
					resolve(returnDatas);
				}
			},
			(error) => {
				window.location.href = "/login";
				reject(error);
			}
		);
	});
};

export const putUser = (userToSave) => {
	const returnDatas = defaultReturnObject;

	const userClone = structuredClone(userToSave);
	userClone?.logged && delete userClone.logged;
	//userClone?.settings && delete userClone.settings;

	return new Promise((resolve, reject) => {
		userApi.put(userClone).then(
			(result) => {
				returnDatas.status = "success";
				returnDatas.message = "";
				resolve(returnDatas);
			},
			(error) => {
				reject(returnDatas);
			}
		);
	});
};

export const deleteUser = (idUser) => {
	const returnDatas = defaultReturnObject;

	return new Promise((resolve, reject) => {
		userApi.delete(idUser).then(
			(result) => {
				returnDatas.status = "success";
				returnDatas.message = "";
				resolve(returnDatas);
			},
			(error) => {
				reject(returnDatas);
			}
		);
	});
};

export const postPassword = (password) => {
	const returnDatas = defaultReturnObject;

	return new Promise((resolve, reject) => {
		userApi.postPassword(password).then(
			(result) => {
				if (result.status === "ok") {
					returnDatas.status = "success";
					returnDatas.message = "";
					returnDatas.data = result;
					resolve(returnDatas);
				} else {
					reject(returnDatas);
				}
			},
			(error) => {
				reject(returnDatas);
			}
		);
	});
};

export const postLogin = (login, password) => {
	const returnDatas = defaultReturnObject;

	return new Promise((resolve, reject) => {
		userApi.postLogin(login, password).then(
			(result) => {
				if (result.status === "ok") {
					returnDatas.status = "success";
					returnDatas.data = result;
					returnDatas.message = "";
					resolve(returnDatas);
				} else {
					returnDatas.message = "login_failed";
					reject(returnDatas);
				}
			},
			(error) => {
				console.log(error);
				reject(error);
			}
		);
	});
};

export const postRegister = (
	email ,
	password ,
	id_country
) => {
	const returnDatas = defaultReturnObject;

	return new Promise((resolve, reject) => {
		userApi.postRegister(email, password, id_country).then(
			(result) => {
				if (result.status === "ok") {
					returnDatas.status = "success";
					returnDatas.data = result;
					returnDatas.message = "";
					resolve(returnDatas);
				} else {
					returnDatas.message = "register_failed";
					reject(returnDatas);
				}
			},
			(error) => {
				console.log(error);
				reject(error);
			}
		);
	});
};

export const postCredentials = (provider, credentials) => {
	const returnDatas = defaultReturnObject;

	return new Promise((resolve, reject) => {
		userApi.postCredentials(provider, credentials).then(
			(result) => {
				if (result.status === "ok") {
					returnDatas.status = "success";
					returnDatas.data = result;
					returnDatas.message = "";
					resolve(returnDatas);
				} else {
					returnDatas.message = "credentials_failed";
					reject(returnDatas);
				}
			},
			(error) => {
				console.log(error);
				reject(error);
			}
		);
	});
};

export const getNewsletters = () => {
	return new Promise((resolve, reject) => {
		userApi.getNewsletters().then(
			(result) => {
				resolve(result);
			},
			(error) => {
				console.log(error);
				reject(error);
			}
		);
	});
}


export const updateNewsletters = (isAdd = true,id_newsletter) => {
	if(isAdd){
		return new Promise((resolve, reject) => {
			userApi.postNewsletters([id_newsletter]).then(
				(result) => {
					resolve(result);
				},
				(error) => {
					console.log(error);
					reject(error);
				}
			);
		});
	}else{
		return new Promise((resolve, reject) => {
			userApi.deleteNewsletters([id_newsletter]).then(
				(result) => {
					resolve(result);
				},
				(error) => {
					console.log(error);
					reject(error);
				}
			);
		});
	}
	
}