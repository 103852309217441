import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

// UI components
import {
	Button,
	Col,
	Alert,
	Form,
	Row,
	Spinner,
	Container,
	FloatingLabel,
} from "react-bootstrap";

import {
	getNewsletters,
	updateNewsletters,
} from "../../Api/Controllers/UserController";

import DefaultAvatar from "../../Assets/Images/default.png";
import defaultPass from "../../Assets/Images/students/passeport.jpg";

import ApiObject from "../../Services/Api";

import SignatureCanvas from "react-signature-canvas";
import Header from "../Parts/Header";
import Api from "../../Services/Api";

import Config from "../../Config";

export default function DashboardSettings() {
	let navigate = useNavigate();

	const [user, setUser] = useState({});
	const [hasOrder, setHasOrder] = useState(false);
	const [currentOrder, setCurrentOrder] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [avatar, setAvatar] = useState(DefaultAvatar);
	const [error, setError] = useState(false);
	const [showError, setShowError] = useState(false);

	const [real, setReal] = useState("");
	const [actor, setActor] = useState("");

	const [letter, setLetter] = useState(false);
	const [pdf, setPdf] = useState(false);

	const [newsletters, setNewsletters] = useState([]);

	const [order, setOrder] = useState({
		id_place: 0,
		id_user: 0,
		signature: "",
	});

	const signFile = React.createRef();

	// redux elements
	const dispatch = useDispatch();

	const saveSettings = async (e) => {
		e.preventDefault();
		e.stopPropagation();

		setIsLoading(true);

		let data = {
			realisateur: real,
			acteur: actor,
		};

		await saveUserInfos();
		ApiObject.call("user/settings", "POST", data, true).then(
			(response) => {
				setTimeout(() => setIsLoading(false), 500);
			},
			(error) => {
				console.log(error);
			}
		);
	};

	const saveUserInfos = async () => {
		let userCandidate = { ...user, password: "" };

		userCandidate.journal_papier = letter;
		userCandidate.journal_pdf = pdf;

		ApiObject.call("user/update", "POST", userCandidate, true).then(
			(response) => {
				setTimeout(() => setIsLoading(false), 500);
			},
			(error) => {
				console.log(error);
			}
		);
	};

	const updateNewsletter = (value, id) => {
		updateNewsletters(value, id).then((result) => {
			console.log("updateNewsletter", result);
		});
	};

	useEffect(() => {
		Api.call("user/me", "GET", {}, true).then(
			(response) => {
				if (response.id) {
					if (response.imageUri !== "") {
						setAvatar(response.imageUri);
					}

					setUser(response);
					dispatch({
						type: "UPDATE_USER",
						payload: response,
					});
				} else {
					navigate("/login");
				}

				setIsLoading(false);
			},
			(error) => {}
		);

		getNewsletters().then((result) => {
			setNewsletters(result.data);
		});
	}, []);
	return (
		<section className={"settings"}>
			<Header />
			<Container>
				<div className={"dashboard_orders"}>
					<Form onSubmit={saveSettings}>
						<Row className={"row_title"}>
							<Col md={9}>
								<h3>Mes préférences</h3>
							</Col>
							<Col md={3} className={"col_action"}>
								<Button
									variant="primary"
									type="submit"
									className={"primary button"}
								>
									Enregistrer
								</Button>
							</Col>
						</Row>
						<Row>
							<Col md={6}>
								<div className="card">
									<span>Journal des Grignoux</span>
									<Form.Group className="mb-3">
										<Form.Check // prettier-ignore
											type="switch"
											id="switch-letter"
											label={
												"Je souhaite recevoir le journal papier dans ma boîte aux lettres"
											}
											value={letter}
											onChange={(e) => {
												setLetter(e.target.checked);
											}}
										/>
									</Form.Group>
									<Form.Group className="mb-3">
										<Form.Check // prettier-ignore
											type="switch"
											id="switch-pdf"
											label={
												"Je souhaite recevoir le journal PDF dans ma boîte mail en priorité, dès qu’il est bouclé"
											}
											value={pdf}
											onChange={(e) => {
												setPdf(e.target.checked);
											}}
										/>
									</Form.Group>
								</div>
								<div className="card">
									<span>Préférences cinématographiques</span>
									<Form.Group className="mb-3">
										<FloatingLabel
											label="Votre réalisateur/réalisatrice préféré.e"
											className="mb-3"
										>
											<Form.Control
												type="text"
												placeholder="Votre réalisateur préféré.e"
												name={"realisateur"}
												value={real}
												onChange={(e) => {
													setReal(e.target.value);
												}}
											/>
										</FloatingLabel>
									</Form.Group>
									<Form.Group className="mb-3">
										<FloatingLabel
											label="Votre acteur/actrice préféré.e"
											className="mb-3"
										>
											<Form.Control
												type="text"
												placeholder="Votre acteur/actrice préféré.e"
												name={"acteur"}
												value={actor}
												onChange={(e) => {
													setActor(e.target.value);
												}}
											/>
										</FloatingLabel>
									</Form.Group>
								</div>
							</Col>

							<Col md={6}>
								<div className="card">
									<span>Newsletters</span>
									<iframe
										width="100%"
										height="1070"
										src="https://a2f9492f.sibforms.com/serve/MUIFABH9czn5UKKZy2k4GcDQulJ_hMrMFOsNqIKW1qeibz9vfS992L6SK4NR6nARcyQjuOfDG_IWqFjK74HBM3fhXfP-hi0fZjX09QdMTvF1HrKn4biyYzbpdEwfjZG6b9qXqaryiUbTkaojw2ExI0RXrx-3E9HgmFVwZaqdvHHglk7CpEQsadRFVsPSnygW-U5ueC6jQuGbBhhd"
									></iframe>

									<iframe
										width="100%"
										height="505"
										src="https://a2f9492f.sibforms.com/serve/MUIEAETC7pYRddEgu8TvQhZykFsZovJz2AhytyQWtMHXtIkbXUqKOrL73Mz6ZuPNtCNBf56thX5_8GZ5jnC4GMILEEyD6t_0o9Jn8d19-vMrdL-jGPllAPQkeLoOAa8Y6g56nN7a6uNI5QyVnAyBZIMvug8VzP64aussxe4r0NM0CWYcVgh-IijR8v0LelzZLEUKJLFzlV2BkXbh"
									></iframe>
								</div>
							</Col>
							{/*
						   <Col md={6}>
								<div className="card">
									<span>Newsletters & communications</span>
									{newsletters.map((newsletter, index) => (
										<div className="newsletter">
											<Form.Check // prettier-ignore
												type="switch"
												id={"switch-" + newsletter.id}
												label={newsletter.name}
												value={newsletter.subscribed}
												onChange={(e) => {
													updateNewsletter(e.target.checked, newsletter.id);
												}}
											/>
										</div>
									))}
								</div>
							</Col>
											*/}
						</Row>
					</Form>

					{showError ? (
						<Alert variant="danger">
							<p>{error}</p>
						</Alert>
					) : (
						<div></div>
					)}
				</div>
			</Container>
		</section>
	);
}
