import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

// UI components
import {
	Button,
	Col,
	Alert,
	Form,
	Row,
	Spinner,
	Container,
	FloatingLabel,
} from "react-bootstrap";
import DefaultAvatar from "../../Assets/Images/default.png";
import defaultPass from "../../Assets/Images/students/passeport.jpg";

import ApiObject from "../../Services/Api";

import SignatureCanvas from "react-signature-canvas";
import Header from "../Parts/Header";
import Api from "../../Services/Api";

import Config from "../../Config";

export default function DashboardOrders() {
	let navigate = useNavigate();

	const [user, setUser] = useState({});
	const [hasOrder, setHasOrder] = useState(false);
	const [currentOrder, setCurrentOrder] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [avatar, setAvatar] = useState(DefaultAvatar);
	const [error, setError] = useState(false);
	const [showError, setShowError] = useState(false);

	const [order, setOrder] = useState({
		id_place: 0,
		id_user: 0,
		signature: "",
	});

	const signFile = React.createRef();

	// redux elements
	const dispatch = useDispatch();

	useEffect(() => {
		setIsLoading(true);
		Api.call("user/me", "GET", {}, true).then(
			(response) => {
				if (response.id) {
					if (response.imageUri !== "") {
						setAvatar(response.imageUri);
					}

					setUser(response);
					setOrder({ ...order, id_user: response.id });
					dispatch({
						type: "UPDATE_USER",
						payload: response,
					});
				} else {
					navigate("/login");
				}

				setIsLoading(false);
			},
			(error) => {}
		);
		Api.call("order/me", "GET", {}, true).then(
			(response) => {
				if (response.student || response.unlimited) {
					setHasOrder(true);
					setCurrentOrder(response);
				}
				setIsLoading(false);
			},
			(error) => {}
		);
	}, []);

	const sendOrder = (e) => {
		e.preventDefault();
		e.stopPropagation();
		setIsLoading(true);

		const orderCandidate = {
			...order,
			id_user: user.id,
			signature: signFile.current.toDataURL(),
		};
		console.log(orderCandidate);

		setOrder(orderCandidate);

		const validation = checkOrderDatasValidity();

		if (validation.status) {
			ApiObject.call("order", "POST", orderCandidate, true).then(
				(response) => {
					setTimeout(() => {
						setIsLoading(false);
						setShowError(false);
						setIsSubmitted(true);
					}, 500);
				},
				(error) => {
					console.log(error);
					setIsLoading(false);
					setError("Une erreur est survenue. Veuillez réessayer plus tard.");
					setShowError(true);
				}
			);
		} else {
			setIsLoading(false);
			setError(validation.message);
			setShowError(true);
		}
	};

	const checkOrderDatasValidity = () => {
		let validationDatas = {
			status: false,
			message: "",
		};

		// Check basic user informations
		if (
			user.id &&
			user.firstname &&
			user.name &&
			user.email &&
			user.address &&
			user.locality &&
			user.cp
		) {
			// check order informations
			if (
				order.id_user &&
				order.id_place > 0 &&
				signFile.current.toData().length > 0
			) {
				// Everything is complete, return true
				validationDatas.status = true;
			} else {
				validationDatas.message =
					"Veuillez indiquer un lieu de retrait et signer votre demande.";
			}
		} else {
			validationDatas.message =
				"Veuillez compléter vos informations générales.";
		}

		return validationDatas;
	};

	return (
		<section className={"dashboard"}>
			<Header />
			<Container>
				<div className={"dashboard_orders"}>
					{showError ? (
						<Alert variant="danger">
							<p>{error}</p>
						</Alert>
					) : (
						<div></div>
					)}
					<Row className={"row_title"}>
						<Col md={9}>
							<h3>Commander une carte</h3>
						</Col>
						<Col md={3} className={"col_action"}>
							<Button
								variant="primary"
								type="submit"
								className={"primary button"}
								disabled
							>
								Commander
							</Button>
						</Col>
					</Row>
					<Row>
						{currentOrder.student ? (
							<div className={"pass_request"}>
								<div className="thumb">
									<img src={defaultPass} alt="" />
								</div>
								<div className="infos">
									<strong>Demande de passeport étudiant</strong>
									<span>
										Demande effectuée le {currentOrder.student.date_formatted}
									</span>
								</div>
								<a
									className={"button primary"}
									target={"_blank"}
									href={
										Config.apiUrl +
										"upload/orders/students/" +
										user.id +
										"_" +
										currentOrder.student.date_formatted +
										".pdf"
									}
								>
									Voir la confirmation PDF
								</a>
							</div>
						) : (
							<div></div>
						)}

						{currentOrder?.unlimited && hasOrder ? (
							<div className={"pass_request"}>
								<div className="thumb"></div>
								<div className="infos">
									<strong>Demande de carte illimitée</strong>
									<span>
										Demande effectuée le {currentOrder?.student?.date_formatted}
									</span>
								</div>
							</div>
						) : (
							<div></div>
						)}

						{!currentOrder.unlimited && !currentOrder.student ? (
							<span className="empty">Aucune demande de carte effectuée.</span>
						) : (
							<span></span>
						)}
					</Row>
				</div>
			</Container>
		</section>
	);
}
