const INITIAL_STATE = {
    user : {'password' : ''}
};


function userReducer(state = INITIAL_STATE,action){

    switch(action.type){
        case "CHECK_LOGIN":{
            return {...state,user:action.payload}
        }

        case "UPDATE_USER":{
            return {...state,user:action.payload}
        }
    }
    return state;
}

export default userReducer;


// return a new dispatch
export const save_user = () => dispatch => {
    //ApiObject.
}
