import {createStore,combineReducers,applyMiddleware} from "redux";
import thunk from "redux-thunk";

import userReducer from "./userReducer";

const rootReducers = combineReducers({
    userReducer
})

const store = createStore(rootReducers,applyMiddleware(thunk));

export default store;
