import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import Select from "react-select";

// Ui Components
import {
  Button,
  Col,
  Container,
  Alert,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import "react-tabs/style/react-tabs.css";
//import HeaderImage from "../../Assets/Images/students/header.png";
import HeaderImage from "../../Assets/Images/pass/background.png";

//import Background from "../../Assets/Images/students/background.jpg";
import Background from "../../Assets/Images/pass/background.png";
//import Passeport from "../../Assets/Images/students/passeport.jpg";
import Passeport from "../../Assets/Images/pass/pass_2024.png";
import Cover from "../../Assets/Images/pass/pass_cover_2024.png";

import ApiObject from "../../Services/Api";
import Api from "../../Services/Api";

const options = [
  { value: 1, label: "le Parc" },
  { value: 2, label: "Churchill" },
  { value: 3, label: "Sauvenière" },
  { value: 4, label: "Caméo" },
];

export default function StudentForm() {
  const selectField = React.createRef();
  const newsletterPasseportField = React.createRef();
  const newsletterLiegeField = React.createRef();
  const newsletterNamurField = React.createRef();

  const defaultStudentFormFields = {
    name: "",
    firstname: "",
    birthdate: "",
    email: "",
    school: "",
    cinema: [],
    gsm: "",
    newsletter: {},
  };

  const [isLoading, setIsLoading] = useState(false);
  const [isTooOld, setIsTooOld] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [hasAPIError, setHasAPIError] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [submitFields, setSubmitFields] = useState(defaultStudentFormFields);


  const formatDdn = ddn => {

      let formatedDDn = ddn.replace(/\D/g, '');
      const ddnArrayValue = formatedDDn.split('');
      let newArrayOfDDNValue = [];
      let i = 0;
      for(const val of ddnArrayValue){
        if(i === 2 || i === 4){
          newArrayOfDDNValue.push('/');
        }
        if(i < 8){
          newArrayOfDDNValue.push(parseInt(val));
        }

        i++;
      }

      const formattedDdn = newArrayOfDDNValue.join('');
      validateDDN(formattedDdn);
      return formattedDdn;
  }

  const validateEmail = (mail) => {
  const testEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const valideEmail = testEmail.test(mail); 
    return valideEmail;
  }

  const validateDDN = ddn => {

    const dateArray = ddn.split('/');
    const birthdate = new Date(dateArray[2]+'/'+dateArray[1]+'/'+dateArray[0]);
    const diffDate = Math.abs(birthdate.getFullYear() - new Date().getFullYear())

    const res = diffDate <= 26 && diffDate >= 12 && ddn.toString().split('').length === 10?true:false;
    return res;
  }

  const attemptRegister = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setIsLoading(true);

    let updatedFields = { ...submitFields };
    updatedFields.newsletter = {
      passeport: newsletterPasseportField.current.checked,
      //liege: newsletterLiegeField.current.checked,
      //namur: newsletterNamurField.current.checked,
    };

    updatedFields.cinema = [];
    for (const val of selectField.current.getValue()) {

      updatedFields.cinema.push(val.value);
    }


    if(
        updatedFields.name === '' || 
        updatedFields.firstname === '' ||
        updatedFields.cinema.length === 0 ||
        updatedFields.school === '' || 
        !validateEmail(updatedFields.email)
        ){
            setHasError(true);
            setIsLoading(false);
        }else{
            setHasError(false);
            if(!validateDDN(updatedFields.birthdate)){            
                setIsTooOld(true);   
                setIsLoading(false);             
            }else{
                setIsTooOld(false);

                setSubmitFields(updatedFields);
                ApiObject.call("order/student", "POST", updatedFields, false).then(
                  (response) => {

                    if(response.success){
                      setIsSubmitted(true);
                      setIsLoading(false);
                    }else{
                      setIsLoading(false);
                      setHasAPIError(true);
                    }
                   
                  }
                );

            }
        }

  };

  return (
    <section className={"studentForm"}>
      <div className={"pageContent"}>

        <div className={"cover"}>
          <div className="inner"></div>
        </div>

        <Container className={"containerStudent"}>
          <Row>
            <Col lg={4} sm={12}>
              <img
                src={Cover}
                className={"cover"}
                alt={"Passeport Étudiant"}
              />
            </Col>
            <Col lg={8} sm={12} className={'textForm '+(isSubmitted?'hide':'show')}>
            <img
                src={Passeport}
                className={"passeport"}
                alt={"Passeport Étudiant"}
              />
              <strong className={"baseLine"}>Tu as entre 12 et 26 ans ?<br />Tu es étudiant·e ?</strong>
              <strong className={"subBaseLine"}>
                Bénéficie d’un prix réduit dans nos 4 cinémas !
              </strong>
              <p className={"infoPasseport"}>
                Ce passeport te permet d’accéder <strong>toute l’année</strong> aux séances (hors événements) des cinémas le Parc, Sauvenière, Churchill et Caméo
                au prix de <strong>6 €</strong> (au lieu de 9 €).
              </p>
            </Col>
          </Row>
          <Row>
            <Col lg={4} sm={12}></Col>
            <Col lg={8} sm={12}>
              <Form onSubmit={attemptRegister} className={'studentFormFields '+(isSubmitted?'hide':'show')} >
                <Row>
                  <Col sm={12}>
                    <Form.Group className="mb-3" controlId="formLastname">
                      <Form.Label>Nom <sup>*</sup></Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Nom"
                        name={"name"}
                        required
                        value={submitFields.name}
                        onChange={(e) => {
                          setSubmitFields({
                            ...submitFields,
                            name: e.target.value,
                          });
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={12}>
                    <Form.Group className="mb-3" controlId="formFirstname">
                      <Form.Label>Prénom <sup>*</sup></Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Prénom"
                        name={"firstname"}
                        required
                        value={submitFields.firstname}
                        onChange={(e) => {
                          setSubmitFields({
                            ...submitFields,
                            firstname: e.target.value,
                          });
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <Form.Group className="mb-3" controlId="formLastname">
                      <Form.Label>Date de naissance <sup>*</sup> <span className='remember'>passeport destiné aux 12 &gt; 26 ans</span></Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="jj/mm/aaaa"
                        name={"ddn"}
                        required
                        value={submitFields.birthdate}
                        className={'dateField '+(isTooOld?'wrong':'')}
                        onChange={(e) => {
                          setSubmitFields({
                            ...submitFields,
                            birthdate:formatDdn(e.target.value),
                          });
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={12}>
                    <Form.Group className="mb-3" controlId="formFirstname">
                      <Form.Label>Établissement scolaire <sup>*</sup></Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Établissement scolaire"
                        name={"school"}
                        required
                        value={submitFields.school}
                        onChange={(e) => {
                          setSubmitFields({
                            ...submitFields,
                            school: e.target.value,
                          });
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <Form.Group className="mb-3" controlId="formLastname">
                      <Form.Label>Mon (mes) cinéma(s) habituel(s) <sup>*</sup></Form.Label>
                      <Select
                        ref={selectField}
                        closeMenuOnSelect={false}
                        isMulti
                        required
                        options={options}
                        placeholder={"Choisir"}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <Form.Group className="mb-3" controlId="formEmail">
                      <Form.Label>Adresse email <sup>*</sup></Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Email"
                        name={"email"}
                        required
                        value={submitFields.email}
                        onChange={(e) => {
                          setSubmitFields({
                            ...submitFields,
                            email: e.target.value,
                          });
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={12}>
                    <Form.Group className="mb-3" controlId="formGsm">
                      <Form.Label>Gsm</Form.Label>
                      <Form.Control
                        type="tel"
                        placeholder="Gsm"
                        name={"gsm"}
                        value={submitFields.gsm}
                        onChange={(e) => {
                          setSubmitFields({
                            ...submitFields,
                            gsm: e.target.value,
                          });
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <span>Je souhaite m'inscrire à :</span>
                  <Col>
                    <Form.Check
                      className={"switchCheck"}
                      type="switch"
                      id="newsletter_passeport"
                      ref={newsletterPasseportField}
                      label="la newsletter liée à ce passeport"
                    />
                    <span className="newsletter_infos">(Concours, goodies, séances exclusives, soirées spéciales, etc.)</span>

                    {/*
                    <Form.Check
                      className={"switchCheck"}
                      type="switch"
                      ref={newsletterLiegeField}
                      id="newsletter_liege"
                      label="la newsletter des activités liégeoises des Grignoux"
                    />

                    <Form.Check
                      className={"switchCheck"}
                      type="switch"
                      ref={newsletterNamurField}
                      id="newsletter_namur"
                      label="la newsletter des activités namuroises des Grignoux"
                    />
                      */}

                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col className={"colValidation"}>
                    <Form.Check
                      required
                      type="switch"
                      id="validField"
                      label={"J’ai pris connaissance des"}
                    />
                    <a
                      href="https://www.grignoux.be/fr/conditions-generales-dutilisation"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      conditions générales d’utilisation
                    </a>
                  </Col>
                </Row>
                <Row>
            {hasError ? (
              <Alert variant="danger" className={"errorMessage"}>
                <p>Veuillez vérifier vos informations avant de continuer.</p>
              </Alert>
            ) : (
              <div></div>
            )}
             {hasAPIError ? (
              <Alert variant="danger" className={"errorMessage"}>
                <p>Une erreur s'est produite.</p>
              </Alert>
            ) : (
              <div></div>
            )}
          </Row>
          <Row>
            {isTooOld ? (
              <Alert variant="danger" className={"errorMessage"}>
                <p>Tu dois avoir entre 12 et 26 ans pour bénéficier du passeport étudiant.</p>
              </Alert>
            ) : (
              <div></div>
            )}
          </Row>
                <Row>
                  <Col className={"colSubmit"}>
                    <Button variant="primary" type="submit">
                      {isLoading ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        <span>Valider</span>
                      )}
                    </Button>
                  </Col>
                </Row>
              </Form>
              <div className={'successMessage '+(isSubmitted?'show':'hide')} >
                <strong>Ton passeport t'attend !</strong>
                <span>Un email de confirmation a été envoyé à l'adresse : {submitFields.email}</span>
              </div>
            </Col>
          </Row>
        </Container>
        <img src={Background} className={"footer"} alt={"Footer"} />
      </div>
    </section>
  );
}
