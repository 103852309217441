import React from 'react';

// UI components
import {Row, Col, Form, Button, Alert} from 'react-bootstrap';
import Logo from '../Assets/Images/logo_grignoux.png';


export default function Lost() {

    return (
       <section className={"lost"}>
           <strong>404</strong>
           <span>La page demandée n'existe pas.</span>
       </section>
    );
}

