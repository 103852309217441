import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";

// Services
import ApiObject from '../../Services/Api';
import Cookie from '../../Services/Cookie';

// UI components
import {Row, Col, Form, Button, Alert} from 'react-bootstrap';
import Logo from '../../Assets/Images/logo_grignoux.png';


export default function Register() {

    const [hasError,setHasError] = useState(false);
    const [submitFields,setSubmitFields] = useState({name:"",firstname:"",email:"",password:""});
    const [isSubmitted,setIsSubmitted] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const cookie = Cookie.get('GRUL');
        if(cookie) navigate('/dashboard');
    },[]);

    const attemptRegister = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if(!submitFields.email || submitFields.email.trim() === ''){
            setHasError(true);
        }
        else if(!submitFields.password || submitFields.password.trim() === ''){
            setHasError(true);
        }else{

            ApiObject.call('user/','POST',submitFields,false).then((response) => {
                if(response){
                    setIsSubmitted(true);
                    setHasError(false);
                }else{
                    setHasError(true);
                }
            },(error) => {
                console.log(error);
            });
        }
    };

    return (
        <section className={"registerForm"}>
                <Row className={"loginRow"}>
                    <Col className={"formCol"} md={6} sm={12}>
                        <img src={Logo} className={"loginLogoApp"} alt={"Grignoux"} />
                        <Form onSubmit={attemptRegister} className={'formContainer'} >
                            <div className={'login_nav'}>
                                <Link to={'/login'} className={'link'}>Connexion</Link>
                                <Link to={'/register'} className={'link active'}>Inscription</Link>
                            </div>

                            <div className={""}>
                                {isSubmitted?
                                    <div className={"successMessage registerSuccess"}>
                                        <span>Votre inscription à bien été enregistrée.</span>
                                        <br />
                                        <br />
                                        <span>Un email de confirmation a été envoyé à l'adresse email : {submitFields.email}</span>
                                    </div>
                                :
                                <div>
                                    <Row>
                                        <Col>
                                            <Form.Group className="mb-3" controlId="formLastname">
                                                <Form.Label>Nom</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Votre Nom"
                                                    name={"name"}
                                                    value={submitFields.name}
                                                    onChange={(e) => {setSubmitFields({...submitFields,name : e.target.value})}}
                                                />

                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="mb-3" controlId="formFirstname">
                                                <Form.Label>Prénom</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Votre Prénom"
                                                    name={"firstname"}
                                                    value={submitFields.firstname}
                                                    onChange={(e) => {setSubmitFields({...submitFields,firstname : e.target.value})}}
                                                />

                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group className="mb-3" controlId="formEmail">
                                                <Form.Label>Email</Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    placeholder="Votre Adresse email"
                                                    name={"email"}
                                                    value={submitFields.email}
                                                    onChange={(e) => {setSubmitFields({...submitFields,email : e.target.value,login : e.target.value})}}
                                                />

                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="mb-3" controlId="formPassword">
                                                <Form.Label>Mot de passe</Form.Label>
                                                <Form.Control
                                                    type="password"
                                                    placeholder="Votre mot de passe"
                                                    value={submitFields.password}
                                                    onChange={(e) => {setSubmitFields({...submitFields,password : e.target.value})}}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Button
                                                variant="primary"
                                                type="submit"

                                            >
                                                Inscription
                                            </Button>
                                        </Col>
                                        <Col></Col>
                                    </Row>
                                </div>
                                }
                            </div>
                            {hasError?
                                <Alert variant="danger" className={"errorMessage"}>
                                    <p>
                                        Veuillez vérifier vos informations avant de continuer.
                                    </p>
                                </Alert>
                                :
                                <div></div>
                            }
                        </Form>
                    </Col>
                    <Col md={6} sm={12} className={"loginBackgroundCol"} />
                </Row>
        </section>
    );
}

