import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// Ui Components
import {
  Button,
  Col,
  Container,
  Alert,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import "react-tabs/style/react-tabs.css";
import HeaderImage from "../../Assets/Images/students/header.png";

//import Background from "../../Assets/Images/students/background.jpg";
import Background from "../../Assets/Images/pass/background.png";

//import Passeport from "../../Assets/Images/students/passeport.jpg";
import Passeport from "../../Assets/Images/pass/pass_2024.png";

import ApiObject from "../../Services/Api";

import { useParams } from "react-router-dom";

export default function StudentValidate() {

const [isLoading,setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [pasPasswordError, setHassPasswordError] = useState(false);
  const [hasSubmittedPassword, setHasSubmittedPassword] = useState(false);
  const [submitFields, setSubmitFields] = useState({
    password: "",
    validation_password: "",
  });

  const { token } = useParams();

  const [hash, setHash] = useState(token);
  const [email, setEmail] = useState("quentin@brainmade.io");

  const validateAccount = () => {
    let requestFields = { hash: hash };

    ApiObject.call("user/validate", "POST", requestFields, false).then(
      (response) => {
        if (response.id) {
          setHasError(false);
          setHash(response.hash);
          setEmail(response.email);

          requestFields = { hash: response.hash };

          // account validated => sending card request confirmation to the user email

          ApiObject.call(
            "order/studentconfirmation",
            "POST",
            requestFields,
            false
          ).then(
            (response) => {
              if (response.id) {
                setHasError(false);
              } else {
                //setHasError(true);
              }
            },
            (error) => {
              //setHasError(true);
            }
          );
        } else {
          //setHasError(true);
        }
      },
      (error) => {
        //setHasError(true);
      }
    );
  };

  const createPassword = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsLoading(true);

    if (
      !submitFields.password ||
      !submitFields.validation_password ||
      submitFields.password.trim() === "" ||
      submitFields.validation_password.trim() === "" ||
      submitFields.password !== submitFields.validation_password
    ) {
      setHassPasswordError(true);
      setIsLoading(false);
    } else {
      let requestFields = { ...submitFields, hash: hash };

      ApiObject.call("user/resetpassword", "POST", requestFields, false).then(
        (response) => {
          setHasSubmittedPassword(true);
          setHassPasswordError(false);
          setIsLoading(false);
        },
        (error) => {
          console.log(error);
          setIsLoading(false);
        }
      );
    }
  };

  useEffect(() => {
    validateAccount();
  },[]);
 


  return (
    <section className={"studentForm"}>
      <div className={"pageContent"}>

        <div className={"cover"}>
          <div className="inner"></div>
        </div>

        <Container className={"containerStudent"}>
          <Row>
            <Col lg={6} sm={12}>
              <img
                src={Passeport}
                className={"passeport"}
                alt={"Passeport Étudiant"}
              />
            </Col>
            <Col lg={6} sm={12} className={"textForm"}>
             {!hasError?
                <div>
      <strong className={"baseLine"}>
                Merci d'avoir validé ton compte
              </strong>
              <strong className={"subBaseLine"}>
                Ton passeport t'attend à l'accueil d'un de nos cinémas
              </strong>
              <p className={"infoPasseport"}>
                Présente-toi à l'accueil avec la confirmation PDF qui a été
                envoyée à ton adresse email.
              </p>
              <div className={"loginInformations"}>
                <p>
                  Tu peux également retrouver cette confirmation dans ton
                  nouveau compte <strong>Mes grignoux</strong>
                </p>
                <Form
                  onSubmit={createPassword}
                  className={
                    "passwordForm " + (hasSubmittedPassword ? "hide" : "show")
                  }
                >
                  <strong>
                    Pour t'y connecter tu dois d'abord définir ton mot de passe
                  </strong>

                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Control
                      type="password"
                      placeholder="Ton mot de passe"
                      value={submitFields.password}
                      onChange={(e) => {
                        setSubmitFields({
                          ...submitFields,
                          password: e.target.value,
                        });
                      }}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Validation du mot de passe</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Retape ton mot de passe"
                      value={submitFields.validation_password}
                      onChange={(e) => {
                        setSubmitFields({
                          ...submitFields,
                          validation_password: e.target.value,
                        });
                      }}
                    />
                  </Form.Group>

                  {pasPasswordError?
                    <span className="errorPassword">Attention, les deux mots de passe ne correspondent pas</span>
                    :<div></div>
                }
                  <Button variant="primary" type="submit">
                    {isLoading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      <span>Créer mon mot de passe</span>
                    )}
                  </Button>
                </Form>
                <div
                  className={
                    "loginBox " + (hasSubmittedPassword ? "show" : "hide")
                  }
                >
                  <div>
                    Tu peux désormais te connecter à ton compte avec ces
                    identifiants :
                    <ul>
                      <li>
                        <strong>Identifiant</strong> : {email}
                      </li>
                      <li>
                        <strong>Mot de passe</strong> :{" "}
                        <span>Celui que tu viens de définir</span>
                      </li>
                    </ul>
                    <Link className={"linkToLogin button primary"} to="/login">
                      Me connecter à mon compte
                    </Link>
                  </div>
                </div>
              </div>
                </div>
            :
                <div className={'errorField'}>
                    <strong>Impossible de valider ton compte.</strong>
                    <br />
                    <span>Pour valider ton compte, clique sur le lien reçu par email.</span>
                    <br />
                    <span>Tu n'as pas reçu l'email ou la validation de fonctionne pas?</span>
                    <span>Tu peux contacter notre équipe à l'adresse suivante <a href="mailto:contact@grignoux.be">contact@grignoux.be</a></span>
            
                </div>
            }
        
            </Col>
          </Row>
          <Row>
            <Col lg={4} sm={12}></Col>
            <Col lg={8} sm={12}></Col>
          </Row>
        </Container>
        <img src={Background} className={"footer"} alt={"Footer"} />
      </div>
    </section>
  );
}
